import { REGION } from "../constants/constants";
import { VideoInterface } from "../interfaces/video.interface";

export function filterQuery(searchIndexes: string[], searchParams: string) {
  return searchIndexes
    .filter((index) => index)
    .some((index) =>
      searchParams
        .trim()
        .split(" ")
        .some((param) => index.toLowerCase().includes(param.toLowerCase()))
    );
}

export function filterVideos(videos: VideoInterface[], isCareflowEnabled: boolean, region: string): VideoInterface[] {
  switch (region) {
    case REGION.US:
      return videos.filter(x => !!x.careflow === isCareflowEnabled || x.type === 'webinar')
        .filter(x => !x.region || x.region?.includes(region));
    case REGION.AU:
    case REGION.GB:
      return videos.filter(x => x.region?.includes(region));
    default:
      return videos;
  }
}
