/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import videoSectionStyles from "../styles/components/video-section.module.scss";
import angleLeft from "../assets/angleLeft.svg";
import playImage from "../assets/play.svg";
import activeImage from "../assets/active.svg";
import { videoData } from "../utils/data/videoData";
import VideoPlayer from "../components/videoPlayer";
import useTrackComponentTime from "../utils/helpers/useTrackComponentTime";
import { PARAMS, TRACKING } from "../utils/constants/constants";
import { TrackContext, UserContext } from "../App";
import useParam from "../utils/helpers/useParam";
import { videoThumbnails } from "../utils/data/thumbnailsData";
import { filterVideos } from "../utils/helpers/filter";
import { useGetRegion } from "../utils/helpers/useGetRegion";

const Video: React.FunctionComponent = () => {
  const videoSource = useLocation().state[0];
  const videoType = useLocation().state[1];
  
  const navigate = useNavigate();
  const videoId = Number(useParams().videoId);
  const [played, setPlayed] = useState<number>(0);
  const trackComponent = useTrackComponentTime(TRACKING.Video.name, {
    videoId,
    videoSource,
  });
  const trackPlayedTime = useContext(TrackContext);
  const careflowEnabled = useParam().get(PARAMS.careflow) === "true";
  const region = useGetRegion();
  const videos = filterVideos(videoData, careflowEnabled, region);
  const currentVideo = videos.find((video) => video.id === videoId);
  const videoUid = Number(currentVideo?.link.replace(/[^0-9]/g, ""));
  const thumbnails = videoThumbnails;
  const activeDivRef = useRef<HTMLDivElement>(null);
  const inActiveDivRef = useRef<HTMLDivElement>(null);
  const [videoHasEnded, setVideoHasEnded] = useState<boolean>(false);
  const userId = useContext(UserContext);
  const [seeked, setSeeked] = useState<number>(0);
  const [prevVideoId, setPrevVideoId] = useState<null | number>(null);
  const videoList = videos
    .reduce((acc: any, video: any) => {
      if (video.type === videoType) {
        return [
          ...acc,
          { ...video, videoDate: new Date(video.date)},
        ];
      }
      return acc;
    }, [])
    .sort(
      (videoA: any, videoB: any) => videoB.videoDate.getTime() - videoA.videoDate.getTime()
      
    );
  useEffect(() => {
    if (videoHasEnded && played > 0) {
      trackPlayedTime &&
        trackPlayedTime({
          userId,
          videoPlayedTime: played,
          videoId: prevVideoId,
        });
      setVideoHasEnded(false);
    }
  }, [videoHasEnded]);

  const handleOnEnd = () => {
    setVideoHasEnded(true);
  };

  const handleStart = () => {
    setPrevVideoId(videoId);
  };

  const handleOnProgress = ({ playedSeconds }: { playedSeconds: number }) => {
    setPlayed(playedSeconds - seeked);
  };

  const handleSeek = (e: any) => {
    setSeeked(e - played);
  };

  
  useEffect(() => {
    trackComponent();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      activeDivRef.current &&
        activeDivRef.current.scrollIntoView({
          block: "start",
        });
    }, 40);
  }, []);

  const handleNavigation = (path: string, state?: any) => {
      setVideoHasEnded(true)
      state && setSeeked(0);
      trackPlayedTime &&
        trackPlayedTime({
          userId,
          videoPlayedTime: played,
          videoId: prevVideoId,
        });
        navigate(path, { state })
  };

  return (
    <div className={videoSectionStyles["c-video-section"]}>
      <div className={videoSectionStyles["c-video-section__left"]}>
        <div className={videoSectionStyles["c-video-section__left-header"]}>
          <div
            className={videoSectionStyles["c-video-section__left-link"]}
            onClick={() => handleNavigation(`/?userId=${userId}&careflow=${careflowEnabled}&region=${region}`, videoType)}
          >
            <img src={angleLeft} alt="Back to Playlist" />
            Back to Playlist
          </div>
        </div>
        
        <div className={videoSectionStyles["c-video-section__left-content"]}>
          <div className={videoSectionStyles["c-video"]}>
              <VideoPlayer
                url={`https://player.vimeo.com/video/${videoUid}&amp;muted=1&amp;autoplay=1`}
                height={340}
                width={604}
                allowFullScreen
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                frameBorder="0"
                allow="autoplay"
                playing={true}
                onEnded={handleOnEnd}
                onStart={handleStart}
                onProgress={handleOnProgress}
                onSeek={handleSeek}
                muted
                controls
              />
            
          </div>
            
          {currentVideo?.category && (
            <div className={videoSectionStyles["c-category-wrapper"]}>
              <div className={videoSectionStyles["c-category"]}>
                {currentVideo?.category}
                
              </div>
              <div className={videoSectionStyles["c-text"]}>{currentVideo?.date ? currentVideo.date : ""}</div>
            </div>
          )}
          <h2 className={videoSectionStyles["c-title"]}>
            {currentVideo?.title}
          </h2>

          <p className={videoSectionStyles["c-text"]}>
            {currentVideo?.description}
          </p>
        </div>
      </div>
      <div className={videoSectionStyles["c-video-section__right"]}>
        <div className={videoSectionStyles["c-video-section__right-header"]}>
          Upcoming Videos
        </div>
        <div className={videoSectionStyles["c-video-section__right-content"]}>
          {videos && videos.length > 0 &&
            videoList.map((video:any, index: number) => {
              return (
                <div
                  key={index}
                  onClick={() => handleNavigation(
                      `../${video.id}?userId=${userId}&careflow=${careflowEnabled}&region=${region}`,
                      [TRACKING.Navigation.fromUpcomingVideos, videoType]
                    )
                  }
                >
                  <div
                    className={`
                  ${videoSectionStyles["c-card-horizontal"]}
                  ${video.id === videoId && videoSectionStyles["is-active"]}`}
                    ref={video.id === videoId ? activeDivRef : inActiveDivRef}
                  >
                    <div
                      className={videoSectionStyles["c-card-horizontal__left"]}
                    >
                      <img
                        src={video.thumbnail ?? thumbnails[video.id]}
                        className={
                          videoSectionStyles["c-card-horizontal__left-image"]
                        }
                        alt=""
                      />
                      <div
                        className={
                          videoSectionStyles["c-card-horizontal__left-play"]
                        }
                      >
                        <img
                          className={
                            videoSectionStyles[
                              "c-card-horizontal__left-play-image"
                            ]
                          }
                          src={playImage}
                          alt="Play"
                        />
                      </div>
                      <div
                        className={
                          videoSectionStyles["c-card-horizontal__left-active"]
                        }
                      >
                        <img
                          className={
                            videoSectionStyles[
                              "c-card-horizontal__left-active-image"
                            ]
                          }
                          src={activeImage}
                          alt="Active"
                        />
                      </div>
                    </div>
                    <div
                      className={videoSectionStyles["c-card-horizontal__right"]}
                    >
                      <label
                        className={
                          videoSectionStyles["c-card-horizontal__category"]
                        }
                      >
                        {video.category}
                      </label>
                      <h2
                        className={
                          videoSectionStyles["c-card-horizontal__title"]
                        }
                      >
                        {video.title}
                      </h2>
                      <div className={videoSectionStyles["c-text"]}>{video.date ? video.date : ""}</div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Video;
