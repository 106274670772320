interface VideoThumbnails {
  [id: number]: string;
}

export const videoThumbnails: VideoThumbnails = {
   113: "https://i.vimeocdn.com/video/1964554199-e394aabb3084ea2d25309e2e79b5607f0b764ba796a788821a2cb90f9c3f579a-d_295x166",
   114: "https://i.vimeocdn.com/video/1946837227-4b17be9ddb853a3e58bc3fc411cdd63517e53367383de7127b5d254bad324bf0-d_295x166",
   115: "https://i.vimeocdn.com/video/1964550975-ce140d6fe3dce8542a304b5fb6589c201b84296e8f0fed040959ca3666c5456c-d_2400",
   116: "https://i.vimeocdn.com/video/1959174866-0d8d807bff9ba822fdf11bf591d3b835c8062c62197ca58ba335e0c0047cf9d6-d_2400",
   117: "https://i.vimeocdn.com/video/1959180749-a734f95e667f7aebda291aa7eeda8b6070e2d035c7593fdd75e6b7e630ba96bc-d_2400",
   118: "https://i.vimeocdn.com/video/1958496883-685ebaece0ac2f924bc8095d9a5a786b4ea5b21a5df9f0ec8db5344f4b87a19d-d_2400",
   119: "https://i.vimeocdn.com/video/1964557124-833c2a21151ca6626824bd8063b5f9ddb22286f5f22cf73f598dab50c3cc2f4c-d_2400",
   120: "https://i.vimeocdn.com/video/1957646509-e7e16db2cddfa29dfdf20fdde590b0492aae18f4383ec4337fa8492c81a0d642-d_2400",
   121: "https://i.vimeocdn.com/video/1958493856-18ba7391e525357edf34f191949546f61cdacc976d09e499fe3e07773000ce73-d_2400",
   122: "https://i.vimeocdn.com/video/1957529327-d73824c3b137575d8619424e0e94a908ee89b46b25fccdb14b137e426f935584-d_2400",
   123: "https://i.vimeocdn.com/video/1947202042-99cb5951acc9c933e1815ab615a2457c109f82f042c15e375b87223930fa1a09-d_2400",
   124: "https://i.vimeocdn.com/video/1948574751-64c7a03564d2e7d7a1c2747412c05f5c0978a0a1c07686a41a174b24c9feefdf-d_2400",
   125: "https://i.vimeocdn.com/video/1957550454-e7b9e1d6ab820815e55f6e122635b09f624d5916f1de3aa0aad017278345f512-d_2400",
   126: "https://i.vimeocdn.com/video/1957556866-70f7090e0937bd11e496d84902c746bc9f114cb67566973aa4b8c6da191d9253-d_2400",
   127: "https://i.vimeocdn.com/video/1957574460-52df37cba6debcf54ce9b7d2f503a446a32ba685c950338e37537792b59b3748-d_2400",
   128: "https://i.vimeocdn.com/video/1957192112-a4e87b06a0d00c532738e104de5aeb743f701cc52ce0d8983c77dc8952145e3b-d_2400",
   129: "https://i.vimeocdn.com/video/1957179113-402bd031258b611b62aa76f75831ef04d67c85afd980b5a9a1e0fe6927b44b4b-d_2400",
   130: "https://i.vimeocdn.com/video/1957238754-ba8b2f82ee68bef88d25fbe2d7ac22b3b7819b7f4404e5120b48189552c71ff2-d_2400",
   131: "https://i.vimeocdn.com/video/1949506023-17e0b58221cb88446008429eb7779414e167dc48f0b3cae6682ef87538001f6b-d_2400",
   132: "https://i.vimeocdn.com/video/1957244345-9506e25bc823e143aac7f06fa12ae21b549dd59276165dd6afe34053ed440266-d_2400",
   133: "https://i.vimeocdn.com/video/1957252777-29d726cf7dca6d0a60d823a742ba4cc0c20841493041bec221bdfeab5f5ff0ec-d_2400",
   134: "https://i.vimeocdn.com/video/1957255502-c14838c520a11ba5c9852ef76c3d252fec01dcb4ed58f8e8017ddb286061a6c8-d_2400",
   135: "https://i.vimeocdn.com/video/1957260672-b7f552ba7b8a546b500e9abd434c130474b8a57bd4695f17d9dd7af0808f2233-d_2400",
   136: "https://i.vimeocdn.com/video/1957269261-c7d504bcf88bc9a329ab577231cc6f1ea7a8fb7c3fcbe28348d728d27488e69e-d_2400",
   137: "https://i.vimeocdn.com/video/1957275460-3532e8ce624803c330ade539dc29c84afda8ebcd45f141ba8f4b902e140df82f-d_2400",
   138: "https://i.vimeocdn.com/video/1959156913-711db00b21491e5f787d1f4cb51d343995aa9a3be3eed2716146c35ab627b1db-d_2400",
   139: "https://i.vimeocdn.com/video/1964564580-67397bbd7cbe3a8f66ab9560c779f9ac2a0678ed5133cd072b06b495bda56500-d_2400",
   140: "https://i.vimeocdn.com/video/1966951418-a7c9b3f2868ea41e7d99589cd413342d0707f402864ed555f8ab0d992809928b-d_2400",
   141: "https://i.vimeocdn.com/video/1957465439-3d2528130474fdcb710ca1f784311b825252da1f28575aefc0ccb444fcf0355e-d_2400",
   142: "https://i.vimeocdn.com/video/1947359760-1a5e32cd9d38cb4179cbb630b67704b957a98dcf0bf3971629000b1645f7dc81-d_2400",
   103: "https://i.vimeocdn.com/video/1921885855-1a741d9f042243569619a62f761d94bcc4bdbbb2300b70aff9e0e7c6729ef42f-d_295x166",
   104: "https://i.vimeocdn.com/video/1921889514-1a17c71a4f26342a40f0c1d4f23bf993e11579b37e49f35de54b0184b98254e7-d_295x166",
   105: "https://i.vimeocdn.com/video/1921893060-ca52ce51d48fd2792d158b189d00b2ffa37e1655aae04f4421d2be84d5d32fb8-d_295x166",
   106: "https://i.vimeocdn.com/video/1921956003-5cfbdca7e9927946718a9ad57bf798dce9c414a7817a3187a3c2273cf7ce12fc-d_295x166",
   107: "https://i.vimeocdn.com/video/1921956683-0e5f5105c14811d657fba0230ca21111f93f0aaeed6bf81ac357b54521734051-d_295x166",
   108: "https://i.vimeocdn.com/video/1921958456-51e7d8973fd89b137ddd0c15bab97e397ae25cb26e96b7ae7440d0785c7cefb0-d_295x166",
   109: "https://i.vimeocdn.com/video/1921959463-c7ddd48d0f1013b2c5b04770f87f5a32efa7aa1be7d3c6245d4e17b773477d3c-d_295x166",
   110: "https://i.vimeocdn.com/video/1921960931-650c0c28cafeab4bf448735f65e3493009ac1a9ea9c0aae0698f06c4c8e84bd1-d_295x166",
   111: "https://i.vimeocdn.com/video/1932580946-8296f1a7fde93bdda2a8a66a239e71d9695414e9bebf52d3c1b2c00f25c5af08-d_295x166",
}

// old video thumbanails used in CS-Learn
// {
//   70: "https://i.vimeocdn.com/video/1868938785-6105e3470a748c57ae3bebc1e0a3b0ecbb4f8dabd1a388eae115831f226241de-d_295x166",
//   71: "https://i.vimeocdn.com/video/1887159386-1066c632f468b8b946128f2ad2b3cf5b6c4ab79da41492e61f53bacbdfdaf01c-d_295x166",
//   72: "https://i.vimeocdn.com/video/1887158915-904cd1a5b20e6547865e51a13f7756f67acdb6f189849a6eb4e1c044132899b4-d_295x166",
//   73: "https://i.vimeocdn.com/video/1887159227-ae8b35b7548df9a6aa69c08d1781eb5261ea5eeafb204ba38264c7705c23fb0c-d_295x166",
//   74: "https://i.vimeocdn.com/video/1887159460-01409b3e2d43c0ddb51f6dcc5f758505d5410a48bf8edfacbb63be6113b02092-d_295x166",
//   75: "https://i.vimeocdn.com/video/1887159589-345084e5fce3dfc530461342f1a1b2fd4d0d2cd772c0aeb6282c932c23713050-d_295x166",
//   76: "https://i.vimeocdn.com/video/1887159078-4e7af1e097789be26202bf0dec02763bf12f95010f750c546d76017535882588-d_295x166",
//   77: "https://i.vimeocdn.com/video/1865051409-5b861d2970bbdaf808bd5900ac701c44d07934ac0ab14f1531db95300c9d84ce-d_295x166",
//   78: "https://i.vimeocdn.com/video/1855669949-200cf6387f682c6bcb8190711d496dba28a3d6f5c58dac21777829721083ebc9-d_295x166",
//   79: "https://i.vimeocdn.com/video/1855671544-11f4c4c647d9c0b65855a6d4b57ee5db3307d8d01258619e736eece424d9872a-d_295x166",
//   80: "https://i.vimeocdn.com/video/1855671579-f546aee2962d3842d80d3faa9bc6428ed174661899e764854b509604a248016c-d_295x166",
//   81: "https://i.vimeocdn.com/video/1856715799-df6225fdc21429c63ace2ca80b35b55e05f5a2f893298b9a317bccba816b9fe2-d_295x166",
//   82: "https://i.vimeocdn.com/video/1855672187-a45b643842e3ef8ae877277f74e56caf393deae9e1586e1acd78203636f46b6c-d_295x166",
//   83: "https://i.vimeocdn.com/video/1855672458-973ec72a8bff1b42401feacf0f4c43567313d7b7253b48537425d882e0522ddb-d_295x166",
//   84: "https://i.vimeocdn.com/video/1865092404-adef44f72a55e5086c82e95f663af267262909348df9e500429e92ed5106d809-d_295x166",
//   85: "https://i.vimeocdn.com/video/1865092404-adef44f72a55e5086c82e95f663af267262909348df9e500429e92ed5106d809-d_295x166",
//   86: "https://i.vimeocdn.com/video/1855670658-992edc68013533dcb1094544d95a419811caa0fbe78d47bef57f62081c3e9b32-d_295x166",
//   87: "https://i.vimeocdn.com/video/1855670881-d245674fead8a04f7760ea6ef75690f9817ff8626badc09d782072cbb240aad7-d_295x166",
//   88: "https://i.vimeocdn.com/video/1905431207-d2236e004e87415cbc8a9815032f259ef543a11a373a471afabd9f05d800ac08-d_295x166",
//   89: "https://i.vimeocdn.com/video/1874899637-46407564da480e2bcc39f1820e864645a6e0a0e768586d2ef396268cb860a877-d_295x166",
//   90: "https://i.vimeocdn.com/video/1874892326-4e949bda753c4ca56c6139a30fbf56e1355030faca960e139347c80e29582029-d_295x166",
//   91: "https://i.vimeocdn.com/video/1915144402-99dab1ac70e22e32b7ae696bcbd4a676c44442ab4b7d6c70ae2cd21f8a273471-d_295x166",
//   103: "https://i.vimeocdn.com/video/1921885855-1a741d9f042243569619a62f761d94bcc4bdbbb2300b70aff9e0e7c6729ef42f-d_295x166",
//   104: "https://i.vimeocdn.com/video/1921889514-1a17c71a4f26342a40f0c1d4f23bf993e11579b37e49f35de54b0184b98254e7-d_295x166",
//   105: "https://i.vimeocdn.com/video/1921893060-ca52ce51d48fd2792d158b189d00b2ffa37e1655aae04f4421d2be84d5d32fb8-d_295x166",
//   106: "https://i.vimeocdn.com/video/1921956003-5cfbdca7e9927946718a9ad57bf798dce9c414a7817a3187a3c2273cf7ce12fc-d_295x166",
//   107: "https://i.vimeocdn.com/video/1921956683-0e5f5105c14811d657fba0230ca21111f93f0aaeed6bf81ac357b54521734051-d_295x166",
//   108: "https://i.vimeocdn.com/video/1921958456-51e7d8973fd89b137ddd0c15bab97e397ae25cb26e96b7ae7440d0785c7cefb0-d_295x166",
//   109: "https://i.vimeocdn.com/video/1921959463-c7ddd48d0f1013b2c5b04770f87f5a32efa7aa1be7d3c6245d4e17b773477d3c-d_295x166",
//   110: "https://i.vimeocdn.com/video/1921960931-650c0c28cafeab4bf448735f65e3493009ac1a9ea9c0aae0698f06c4c8e84bd1-d_295x166",
//   111: "https://i.vimeocdn.com/video/1932580946-8296f1a7fde93bdda2a8a66a239e71d9695414e9bebf52d3c1b2c00f25c5af08-d_295x166",
// };
