export const BASEURL = "http://localhost:4002";
export const TABNAMES = [
  { name: "Quick Start Playlist", url: "/", contentType:"generic", region: ['US', 'AU', 'GB', 'IE', 'SG'] },
  { name: "Webinars and Bootcamp", url: "/webinar", contentType:"webinar", region: ['US'] },
  { name: "Guide to Reporting", url: "reporting", contentType:"reporting", region: ['US'] },
];

export const REGIONS = ['US', 'AU', 'GB', 'IE', 'SG'];

export enum REGION {
  US = 'US',
  AU = 'AU',
  GB = 'GB',
  IE = 'IE',
  SG = 'SG'
}

export enum TABS {
  Playlist,
  Webinars,
  Reporting
}

export const PARAMS = {
  userId: 'userId',
  careflow: 'careflow',
  region: 'region'
}

export const PLAYLIST = {
  searchPlaceHolder: "Search for a video",
  noResultText:"No result found"
};

export const REPORTS = {
  title: "Reports",
  searchPlaceHolder: "Search for a report",
  containerTitles: ["Primary Use-cases", "Similar Reports"],
};

export const TRACKING = {
  App: {
    name: "App",
  },
  Playlist: {
    name: "Playlist Page",
  },
  Reporting: {
    name: "Reporting Page",
  },
  Video: {
    name: "Video Page",
    playedTimeEventName: "Video played time",
  },
  Navigation: {
    fromHome: "From Main Page",
    fromUpcomingVideos: "From Upcoming Videos",
  },
};
